import React, { Fragment, useState, useEffect, MouseEventHandler } from "react"
import { PageTitle, SpanDivider } from "../components/UtilComponents"
// @ts-ignore
import Fade from "react-reveal/Fade"
import SEO from "../components/seo"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Img from "gatsby-image"
// @ts-ignore
import { getSectionsData} from "../util/helpers"
import styled from "styled-components"
import HomePageVideo from "../components/Video/HomePageVideo"
import {MdPlayCircleOutline} from "react-icons/md"

const BlogMediaListWrapper = styled(({className, posts, lang}) => {
  const [videoSource, setVideoSource] = useState("")
  const [openVideo, setOpenVideo] = useState(false)
  const handleClick = (source:string, e: MouseEvent) => {
    setVideoSource(source)
    setOpenVideo(true)
  }
  const closeVideo = () => {
    setOpenVideo(false)
  }
  return <div className={`${className} blog-list-wrapper`}>
    <div className={`promo-video ${openVideo ? "open" : null}`}>
      {openVideo ?
        <HomePageVideo opened={openVideo} source={videoSource} handleClose={closeVideo} /> : null}
    </div>
    <div className="blog-list">
      {posts.length > 0 ? posts.map((p, index) => {
        const local = p.locales.find(l => l.iso === lang)
        const videoID = p.additionalData.find(ad => ad.key === 'VideoID').locales.find(l => l.iso === lang).value
        return <div key={index} onClick={handleClick.bind(null, videoID)} className="list-item">
            <Img fluid={p.imageFile.childImageSharp.fluid} />
            <div className="info">
              <h2>{local.title}</h2>
              {local.content ? <p className="content">
                {local.content}
              </p>: null}
            </div>
            <div className="play-button">
              <MdPlayCircleOutline color="white" size={60} />
            </div>
        </div>
      }) : null }
    </div>
  </div>
})`
  .blog-list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 50px;
  }

  .list-item {
    position: relative;
    width: 100%;
    max-width: 650px;
    max-height: 350px;
    display: flex;
    flex-direction: column;
    
    img {
      display: block;
      width: 100%;
      transition: all .3s ease-in !important;
    }
    
    &:hover {
      cursor: pointer;
      img {
        transform: scale(1.1)
      }
      .play-button {
        visibility: visible;
        opacity: 1;
      }
    }

    h2 {
      text-align: center;
    }
  }

  .play-button {
    display: flex;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    width: 100%;
    top:0;
    bottom: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.57);
    transition: all .3s ease-in;
  }

  .promo-video {
    padding: 100px 2% 2%;
    @media screen and (min-width: 900px) and (max-width: 1050px) {
      padding-top: 150px;
    }
    @media screen and (min-width: 1050px) {
      padding-top: 7%;
    }
    // max-width:1200px;
    position: fixed;
    top: 0;
    // left:50%;
    // top:50%;
    // transform:translate(-50%, -50%);
    width: 100%;
    height: 100%;
    z-index: 1000;
    display: none;
    background: black;

    &.open {
      display: block;
    }
  }
`

// @ts-ignore
function BlogListMedia({data,pageContext, location:{pathname:path}, className}) {

  // @ts-ignore
  return (
    <Layout lang={pageContext.lang} page={path} empty>
      <SEO title="Home" />
      <div className="content content--blog content--inner">
        <Fade top>
          <PageTitle>
            <SpanDivider fontSize="calc(30px + (40 - 30) * ((100vw - 300px) / (900 - 300)))"> | </SpanDivider> Media
          </PageTitle>
          {/*<span style={{textAlign: "center", fontSize:"18px", fontWeight: "bold", display:"block"}} className="content--blog__subtitle">{secData.head.description[pageContext.lang]}</span>*/}
        </Fade>
        <BlogMediaListWrapper lang={pageContext.lang} posts={data.allPost.nodes} />
      </div>
    </Layout>
  )
}
export const query = graphql`
    {
        allPost(filter: {category: {locales: {elemMatch: {title: {eq: "Media"}}}}}) {
            nodes {
                additionalData {
                    key
                    locales {
                        value
                        iso
                    }
                }
                category {
                    locales {
                        iso
                        title
                        description
                    }
                }
                imageFile {
                    childImageSharp {
                        fluid(cropFocus: CENTER, maxWidth: 650,maxHeight: 350) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                locales {
                    title
                    value
                    iso
                    content
                }
            }
        }
    }
`
export default BlogListMedia
